import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector, shallowEqual } from 'react-redux';
import { selectGamesByCategory, selectRandomGames } from '../store/gamesSlice';
import '../styles/carousel.css';

const CasinoGames = (props) => {

    const {
        title,
        categoryId,
        sectionId,
        button,
        gameButton,
        url,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const selectFunction = title === 'Recommended Games' ? selectRandomGames : (state) => selectGamesByCategory(state, categoryId);
    const gameList = useSelector(selectFunction, shallowEqual);
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;
            
            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = useCallback(() => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    }, [carouselRef]);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, [checkArrowsVisibility]);

    useEffect(() => {
        checkArrowsVisibility();
    }, [gameList, checkArrowsVisibility]);

    return (
        <div id={sectionId} className='lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                    {title}
                </h2>
                <a
                
                    href={url}
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    {button}
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { gameList.map((game, index) => {
                        const exclusiveGameTag = game.tags.find(tag => tag.key === "bitcoincomExclusiveGame");
                        const gameLink = exclusiveGameTag && exclusiveGameTag.value.includes("true") ?
                            `https://app.games.bitcoin.com/${game.gameId}` :
                            `https://app.games.bitcoin.com/3game?game_id=${game.gameId}${game.hasDemoMode ? '&demo=true' : ''}`;
                        
                        return (
                            <a
                                key={`game-${index}`}
                                href={gameLink}
                                target='_blank'
                                rel='noreferrer'
                            >
                                <div className='flex flex-col bg-custom-21 dark:bg-custom-22 flex-item rounded-xl xl:w-[320px] w-[243px] h-full'>
                                    <img
                                        className='w-full rounded-t-xl'
                                        src={game.image}
                                        alt={`${game.gameId} ${index}`}
                                    />
                                    <div className='flex flex-col justify-between h-full gap-4 py-6 px-4'>
                                        <div className='flex flex-col gap-2'>
                                            <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-normal m-0'>
                                                {game.fullGameName}
                                            </h3>
                                            <p className='text-custom-23 dark:text-custom-20 text-sm font-inter font-normal leading-normal m-0'>
                                                {game.text}
                                            </p>
                                        </div>
                                        <p className='text-custom-13 text-base text-center font-inter font-semibold leading-[17.6px] border border-custom-13 border-solid rounded-xl py-2 px-4 m-0'>
                                            {gameButton}
                                        </p>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default CasinoGames
