import React from "react";
import { MoonIcon } from "./Icons/MoonIcon";
import { SunIcon } from "./Icons/SunIcon";

export const ThemeButtom = ({ theme, toggleTheme }) => {
  return (
    <div
      className="flex gap-2 flex-row lg:flex lg:items-center lg:justify-between w-[70px] h-8 p-1 bg-light rounded-[19px] cursor-pointer"
      onClick={toggleTheme}
    >
      <span
        className={`flex justify-center items-center w-8 h-8 ${
          theme === "dark" && "bg-white rounded-full"
        }`}
      >
        <MoonIcon
          className="mt-1 ml-1"
          color={theme === "light" ? "grey" : "currentColor"}
        />
      </span>
      <span
        className={`flex justify-center items-center w-8 h-8 ${
          theme === "light" && "bg-white  rounded-full"
        }`}
      >
        <SunIcon color={theme === "dark" ? "grey" : "currentColor"} />
      </span>
    </div>
  );
};
