import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useSelector } from "react-redux";
import { ThemeButtom } from "../ThemeButton";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const handleNavbar = () => setIsOpen(!isOpen);
  const countryCode = useSelector((state) => state.countryCode.value);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  const navItems = [
    {
      text: "Advertise",
      link: "mailto:affiliates@bitcoin.com?subject=Games Review Request",
      externalLink: false,
    },
    {
      text: "News",
      link: "https://srt.bitcoin.com/games-news",
      externalLink: true,
    },
    {
      text: "Reviews Home",
      link: "https://www.bitcoin.com/directory/gambling",
      externalLink: true,
    },
    {
      text: "Play At BC Game",
      link: "https://srt.bitcoin.com/bc-games-bcom",
      externalLink: true,
    },
  ];

  useEffect(() => {
    document.body.className = `${theme} ${
      theme === "light" ? "bg-white" : "bg-custom-dark"
    }`;
    localStorage.setItem("theme", theme);

    if (isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [theme, isOpen]);

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <React.Fragment>
      <div className="flex justify-between items-center sticky top-0 bg-white dark:bg-custom-dark z-[1000] w-full mx-auto py-4 text-black-100">
        <a href="/">
          <img
            src={
              theme === "light"
                ? "/images/games-logo.svg"
                : "/images/games-logo-dark.svg"
            }
            alt="Games Logo"
            width={260}
            height={24}
          />
        </a>

        <ul className="hidden lg:flex lg:gap-6">
          {navItems.map((item) => (
            <li key={item.text}>
              <a
                href={
                  countryCode === "US" && item.text === "News"
                    ? item.usLink
                    : item.link
                }
                {...(item.externalLink
                  ? { target: "_blank", rel: "noreferrer" }
                  : {})}
                className="text-custom-1 dark:text-white hover:text-custom-3 text-base font-bold leading-[17.6px] cursor-pointer"
              >
                {item.text}
              </a>
            </li>
          ))}
        </ul>
        {!isTablet && (
          <div className="flex gap-[48px]">
            <ThemeButtom theme={theme} toggleTheme={toggleTheme} />
            <div className="hidden lg:flex lg:items-center lg:gap-6">
              <a
                href="https://srt.bitcoin.com/games-app-signup"
                target="_blank"
                rel="noreferrer"
                className="text-white bg-primary hover:bg-custom-9 text-base font-bold leading-[17.6px] cursor-pointer py-3 px-8 rounded-xl"
              >
                Play Now
              </a>
            </div>
          </div>
        )}

        <div onClick={handleNavbar} className="block lg:hidden">
          {isOpen ? (
            <AiOutlineClose
              color={theme === "light" ? "#000000" : "#FFFFFF"}
              size={20}
            />
          ) : (
            <AiOutlineMenu
              color={theme === "light" ? "#000000" : "#FFFFFF"}
              size={20}
            />
          )}
        </div>

        {isOpen && (
          <div className="overscroll-none z-10 absolute top-[48px] left-0 right-0 w-full h-[calc(100vh-48px)] bg-white dark:bg-custom-dark transition">
            <div className="flex flex-col justify-center h-[calc(100%-130px)] overflow-y-auto">
              <ul className="lg:hidden flex flex-col items-center justify-center m-0 w-full p-0">
                {navItems.map((item) => (
                  <li key={item.text} className="p-4">
                    <a
                      href={item.link}
                      {...(item.externalLink
                        ? { target: "_blank", rel: "noreferrer" }
                        : {})}
                      className="text-custom-1 dark:text-white hover:text-custom-3 text-2xl font-bold leading-8 cursor-pointer"
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
                <li className="p-4">
                  {isTablet && (
                    <ThemeButtom theme={theme} toggleTheme={toggleTheme} />
                  )}
                </li>
              </ul>
            </div>
            <div className="lg:hidden flex flex-col items-center mx-6 gap-4">
              <a
                href="https://srt.bitcoin.com/games-app-signup"
                target="_blank"
                rel="noreferrer"
                className="w-full text-white bg-primary hover:bg-custom-9 text-xl font-bold leading-[22px] text-center cursor-pointer py-[17px] rounded-xl"
              >
                Play Now
              </a>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Header;
