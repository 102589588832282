import React, { useState } from "react";
import { FAQList } from '../helpers/data';

const FAQ = () => {

    const [activeIndex, setActiveIndex] = useState(FAQList.map(() => true));

    const toggleFAQ = (index) => {
        setActiveIndex((prevIndexes) =>
            prevIndexes.map((isActive, idx) =>
                idx === index ? !isActive : isActive
            )
        );
    };

    return (
        <div id="faqSection" className="xl:mt-20 mt-16">
            <h1 className="text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0">
                Frequently Asked Questions
            </h1>
            <div className="flex flex-col gap-6 md:mt-6 mt-4">
                {FAQList.map((faq, index) => (
                    <div
                        key={index}
                        className="bg-custom-21 dark:bg-custom-22 rounded-lg"
                    >
                        <div
                            onClick={() => toggleFAQ(index)}
                            className="flex justify-between items-center bg-custom-21 dark:bg-custom-22 rounded-2xl p-4"
                        >
                            <h2 className="text-custom-4 dark:text-white xl:text-xl md:text-lg text-base font-inter font-semibold xl:leading-8 md:leading-normal leading-[26px] m-0">
                                {faq.question}
                            </h2>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className={`h-5 w-5 text-custom-4 dark:text-white transform duration-500 transition-transform ${activeIndex[index] ? "rotate-180" : ""}`}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                fillRule="evenodd"
                                d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                                />
                            </svg>
                        </div>

                        <div className={`transition-all duration-500 ease-in-out overflow-hidden ${activeIndex[index] ? "max-h-96" : "max-h-0"}`}>
                            <p className="text-custom-23 dark:text-custom-20 xl:text-base text-sm font-inter font-normal leading-[17.6px] p-4 pt-0 m-0">
                                {faq.answer}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
