import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const SmallIconGamesPlayToEarnSection = (props) => {
  
    const {
        GameList,
        Content,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const baseURL = "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-";

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    const splitIntoChunks = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
    
    return (
        <div id={Content.sectionId} className='xl:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                    {Content.title}
                </h2>
                <a
                    href={Content.url}
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    {Content.button}
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    {splitIntoChunks(GameList, 3).map((chunk, chunkIndex) => (
                        <div key={`chunk-${chunkIndex}`} className='flex flex-col shrink-0 md:basis-[31.5%] basis-[70%]'>
                            {chunk.map((game, gameIndex) => (
                                <React.Fragment key={`game-${chunkIndex}-${gameIndex}`}>
                                    <a
                                        href={baseURL + game.name_seo}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <div className='flex gap-4 items-center'>
                                            <img
                                                src={game.profile_picture.original}
                                                alt="game thumbnail"
                                                className='w-[60px] h-[60px] rounded-2xl'
                                            />
                                            { isTablet ? (
                                                <div className='flex flex-col gap-1'>
                                                    <div className='flex gap-2 justify-between items-center'>
                                                        <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-[17.6px] m-0 truncate-1-lines'>
                                                            {game.name}
                                                        </h3>
                                                        <p className='text-custom-13 whitespace-nowrap text-sm text-center font-inter font-bold leading-[15.9px] border border-custom-13 border-solid rounded-xl py-1 px-4 m-0'>
                                                            {Content.responsiveGameButton}
                                                        </p>
                                                    </div>
                                                    <p className='text-custom-23 dark:text-custom-20 text-sm font-inter font-normal leading-[17.6px] m-0 truncate-2-lines'>
                                                        {game.description}
                                                    </p>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <div className='flex flex-col gap-1 grow'>
                                                        <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-[17.6px] m-0 truncate-1-lines'>
                                                            {game.name}
                                                        </h3>
                                                        <p className='text-custom-23 dark:text-custom-20 text-sm font-inter font-normal leading-[17.6px] m-0 truncate-2-lines'>
                                                            {game.description}
                                                        </p>
                                                    </div>
                                                    <p className='text-custom-13 whitespace-nowrap w-16 text-sm text-center font-inter font-bold leading-[15.9px] border border-custom-13 border-solid rounded-xl py-1.5 px-4 m-0'>
                                                        {Content.gameButton}
                                                    </p>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    </a>
                                    {gameIndex !== 2 &&
                                        <hr className="bg-custom-29 dark:bg-custom-28 border-0 h-0.5 m-0 my-4" />
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </div>

                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default SmallIconGamesPlayToEarnSection
