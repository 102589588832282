/* eslint-disable no-nested-ternary */
/* eslint-disable react/button-has-type */
import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {
  ButtonInfo,
  MuteUnmuteButton,
  PausePlayButton,
  NextVideo,
  YoutubeDimension,
  PreviousVideo,
  CloseButton,
} from './styled';

const YouTubeVideoPlayer = forwardRef(
  (
    {
      width = '100%',
      height = '185px',
      onClose,
      showCloseButton = true,
    },
    ref,
  ) => {
    const [isMuted, setIsMuted] = useState(true);
    const [isPlaying, setIsPlaying] = useState(true);
    const [hasUnmutedOnce, setHasUnmutedOnce] = useState(false);
    const [hasClickedNext, setHasClickedNext] = useState(0);
    const iframeRef = useRef(null);

    useImperativeHandle(ref, () => iframeRef.current);

    const baseUrl = 'https://www.youtube.com/embed/videoseries';
    const list = 'PLL-mmY61Sru9VqAEBO49LGGYiiefBPQXl';

    const params = new URLSearchParams({
      list,
      autoplay: '1',
      mute: '1',
      controls: '0',
      enablejsapi: '1',
      cc_load_policy: '1',
      cc_lang_pref: 'en',
    });

    const iframeUrl = `${baseUrl}?${params.toString()}`;

    const handleIframeLoad = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          JSON.stringify({ event: 'listening' }),
          '*',
        );
        iframeRef.current.contentWindow?.postMessage(
          JSON.stringify({ event: 'command', func: 'playVideo' }),
          '*',
        );
      }
    };

    const toggleMute = () => {
      if (iframeRef.current) {
        if (isMuted) {
          if (!hasUnmutedOnce) {
            iframeRef.current.contentWindow?.postMessage(
              JSON.stringify({
                event: 'command',
                func: 'seekTo',
                args: [0],
              }),
              '*',
            );
            setHasUnmutedOnce(true);
          }
          iframeRef.current.contentWindow?.postMessage(
            JSON.stringify({
              event: 'command',
              func: 'unMute',
            }),
            '*',
          );
        } else {
          iframeRef.current.contentWindow?.postMessage(
            JSON.stringify({
              event: 'command',
              func: 'mute',
            }),
            '*',
          );
        }
        setIsMuted(!isMuted);
      }
    };

    const togglePlayPause = () => {
      if (iframeRef.current) {
        if (isPlaying) {
          iframeRef.current.contentWindow?.postMessage(
            JSON.stringify({
              event: 'command',
              func: 'pauseVideo',
            }),
            '*',
          );
        } else {
          iframeRef.current.contentWindow?.postMessage(
            JSON.stringify({
              event: 'command',
              func: 'playVideo',
            }),
            '*',
          );
        }
        setIsPlaying(!isPlaying);
      }
    };

    const playNextVideo = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'nextVideo',
          }),
          '*',
        );
        setHasClickedNext(prev => prev + 1);
      }
    };

    const playPreviousVideo = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'previousVideo',
          }),
          '*',
        );
        setHasClickedNext(prev => prev - 1);
      }
    };

    const handleClose = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'pauseVideo',
          }),
          '*',
        );
      }
      setIsPlaying(false);
      onClose();
    };

    return (
      <YoutubeDimension>
        <iframe
          title="Bitcoin News Playlist"
          ref={iframeRef}
          id="youtube-player"
          src={iframeUrl}
          width={width}
          height={height}
          className='border-none pointer-events-none rounded-t-xl'
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          loading="lazy"
          onLoad={handleIframeLoad}
        />
        {showCloseButton && (
          <CloseButton
            src="./images/close.png"
            alt="close"
            onClick={handleClose}
          />
        )}
        <div style={{ display: 'flex', gap: '10px' }}>
          <MuteUnmuteButton
            onClick={toggleMute}
            aria-label={isMuted ? 'Unmute' : 'Mute'}
          >
            <ButtonInfo>
              <img
                src={`/images/${isMuted ? 'mute' : 'unmute'}.png`}
                alt={isMuted ? 'mute' : 'unmute'}
              />
            </ButtonInfo>
          </MuteUnmuteButton>
          <PausePlayButton
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Pause' : 'Play'}
          >
            <img
              src={`/images/${isPlaying ? 'pause' : 'play'}.png`}
              alt={isPlaying ? 'pause' : 'play'}
            />
          </PausePlayButton>
          {hasClickedNext !== 0 && (
            <NextVideo onClick={playPreviousVideo}>
              <img src="/images/nextVideo.png" alt="Next" />
            </NextVideo>
          )}
          <PreviousVideo onClick={playNextVideo}>
            <img src="/images/previousVideo.png" alt="Previous" />
          </PreviousVideo>
        </div>
      </YoutubeDimension>
    );
  },
);

export default YouTubeVideoPlayer;
