import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layouts from './Components/Common/Layouts';
import Home from './Components/Home';
import TagManager from 'react-gtm-module';
import BitcoinPlayblockIframe from './Components/BitcoinPlayblockIframe';
import NotFound from './Components/NotFound';

const tagManagerArgs = {
  gtmId: 'GTM-5N6TL56'
}

const App = () => {

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layouts />,
      children: [
        {
          path: '/',
          element: <Home />,
        },
      ],
    },
    {
      path: '/up-or-down',
      element: <BitcoinPlayblockIframe />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;
