import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ReviewBannerList } from '../helpers/data';

const ReviewBanner = () => {

    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const bannerDetails = () => {
        if (isMobile) {
            return ReviewBannerList.mobileBannerList;
        } else if (isTablet) {
            return ReviewBannerList.tabletBannerList;
        } else {
            return ReviewBannerList.desktopBannerList
        }
    }
    
    return (
        <div id='reviewSection' className='xl:mt-20 lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                    { ReviewBannerList.title }
                </h2>
                <a
                
                    href={ ReviewBannerList.url }
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    { ReviewBannerList.button }
                </a>
            </div>
            <p className='text-custom-20 dark:text-custom-23 md:text-base text-sm font-inter font-normal md:leading-[17.6px] leading-normal m-0 mt-2'>
                { ReviewBannerList.text }
            </p>
            <div className="flex flex-wrap md:justify-between justify-center bg-custom-21 dark:bg-custom-25 md:py-10 md:px-8 pt-8 pb-6 px-4 rounded-xl gap-y-4 xl:gap-x-6 md:gap-x-[21px] mt-6">
                {bannerDetails().map((label, index) => (
                    <a
                        key={index}
                        href={label.link}
                        target='_blank'
                        rel='noreferrer'
                        className="flex items-center justify-between bg-white dark:bg-custom-27 py-[17px] rounded-xl px-6 xl:w-[calc(25%-72px)] md:w-[calc(33.3333%-69px)] w-full"
                    >
                        <div className="flex justify-center items-center gap-[10px]">
                            <img
                                src={label.icon}
                                alt={label.altText}
                                width={ label.width }
                                height={ label.height }
                                />
                            <h3 className="text-custom-4 dark:text-white xl:text-xl text-lg font-inter font-semibold xl:leading-[26px] leading-normal m-0">
                                {label.text}
                            </h3>
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                            <g opacity="0.5">
                                <path d="M15.5 10.8333V15.8333C15.5 16.2754 15.3244 16.6993 15.0118 17.0118C14.6993 17.3244 14.2754 17.5 13.8333 17.5H4.66667C4.22464 17.5 3.80072 17.3244 3.48816 17.0118C3.17559 16.6993 3 16.2754 3 15.8333V6.66667C3 6.22464 3.17559 5.80072 3.48816 5.48816C3.80072 5.17559 4.22464 5 4.66667 5H9.66667" stroke="#657089" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13 2.5H18V7.5" stroke="#657089" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.8335 11.6667L18.0002 2.5" stroke="#657089" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>
                ))}
                {isMobile && (
                    <a
                        href={ReviewBannerList.url}
                        target='_blank'
                        rel='noreferrer'
                        className="text-custom-13 text-lg font-inter font-semibold leading-normal"
                    >
                        {ReviewBannerList.viewButton}
                    </a>
                )}
            </div>
        </div>
    )
}

export default ReviewBanner
