import React, { useState } from 'react';
import { useStytch } from '@stytch/react';

const NewsLetterSubscription = () => {
    const stytch = useStytch();
    const [submitting, setSubmitting] = useState(false);
    const [inputEmail, setInputEmail] = useState('');
    const [hasRegistered, setHasRegistered] = useState(false);

    const getStytchUrl = (origin, data) => {
        return `${origin}/manage-account/?data=${JSON.stringify(data)}`;
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputEmail(value);
        e.target.style.backgroundColor = 'revert !important';
    };

    const handleSubmit = async (e) => {
        try {
            e?.preventDefault();
      
            const { location } = window;
            const subscribeTo = ["email_games"];
            const redirectUrl = "https://www.bitcoin.com/newsletters/games-updates/";
            const pathData = { subscribeTo, path: `${location.path}`, redirectUrl };
            const stytchUrl = getStytchUrl("https://www.bitcoin.com", pathData);
      
            if (inputEmail === '') {
              return;
            }
      
            const emailOptions = {
                login_expiration_minutes: 30,
                signup_expiration_minutes: 30,
                login_magic_link_url: stytchUrl,
                signup_magic_link_url: stytchUrl,
                login_template_id: process.env.REACT_APP_STYTCH_LOGIN_TEMPLATE_ID,
                signup_template_id: process.env.REACT_APP_STYTCH_SIGNUP_TEMPLATE_ID,
            };
      
            setSubmitting(true);
            const res = await stytch.magicLinks.email.loginOrCreate(
                inputEmail,
                emailOptions,
            );
      
            if (res.status_code === 200) {
                setHasRegistered(true);
                const params = new URLSearchParams(new URL(stytchUrl).search);
                const data = params.get('data');
                const parsedData = JSON.parse(data);
                const tags = parsedData.subscribeTo;
                const existingTags =
                    JSON.parse(localStorage.getItem('subscribedTags')) || {};
                const updatedTags = {
                    ...existingTags,
                    ...Object.fromEntries(tags.map(tag => [tag, 'subscribed'])),
                };
                localStorage.setItem('subscribedTags', JSON.stringify(updatedTags));
                console.log('subscribedtags', updatedTags);
            } else {
                setHasRegistered(false);
                console.log('Error: inside considtion', e);
            }
            setSubmitting(false);
        } catch {
            setHasRegistered(false);
            setSubmitting(false);
            console.log('Error:', e);
        }
    };

    return (
        <div className='bg-custom-21 dark:bg-custom-22 md:mt-8 mt-4 md:mb-12 mb-8 rounded-2xl w-full'>
            <div className='flex lg:flex-row flex-col justify-between items-center md:px-8 md:py-6 p-4'>
                <div className='flex flex-col lg:justify-start justify-center items-center lg:mb-0 mb-3'>
                    <p className='text-custom-4 dark:text-white xl:text-2xl md:text-xl text-sm font-inter font-semibold lg:text-start text-center md:leading-8 leading-normal m-0'>
                        Subscribe to our Gaming newsletter for a chance
                        <span className='text-primary dark:text-custom-green xl:block'> to win $1,000 every month.<span className='text-custom-4 dark:text-white'>*</span></span>
                    </p>
                </div>
                { hasRegistered ? (
                    <p className='text-custom-4 dark:text-white xl:text-2xl md:text-xl text-base font-inter font-semibold lg:text-end text-center lg:w-1/2 md:w-2/3 w-full md:leading-8 leading-normal m-0 mt-3'>
                        An email was sent to {inputEmail}
                    </p>
                ) : (
                    <form onSubmit={handleSubmit} className="flex items-center justify-center rounded-xl shadow-sm xl:w-1/2 lg:w-3/4 md:w-2/3 w-full border border-gray-300 bg-white pr-2">
                        <input
                            name="email"
                            type="email"
                            placeholder="example@email.com"
                            className="bg-white text-custom-6 md:text-lg text-sm font-inter font-semibold leading-normal focus:outline-none focus:ring-2 focus:ring-custom-9 focus:z-10 rounded-l-xl border-none w-full px-4 py-2 h-12"
                            value={inputEmail}
                            onChange={handleInputChange}
                            disabled={submitting}
                        />
                        <button
                            type="submit"
                            className="bg-custom-4 dark:bg-custom-green hover:bg-custom-9 text-white md:text-lg text-sm font-inter font-semibold md:leading-[22px] leading-[24.3px] focus:outline-none focus:ring-2 focus:ring-blue-400 rounded-xl border-none px-6 h-12"
                            onChange={e => setInputEmail(e.target.value)}
                            disabled={submitting}
                        >
                            Subscribe
                        </button>
                    </form>
                )}
            </div>
        </div>
    )
}

export default NewsLetterSubscription
