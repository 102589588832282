import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const LargeIconGamesSection = (props) => {

    const {
        GameList,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    const splitIntoChunks = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };
    

    return (
        <div id={GameList.sectionId} className='xl:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                    {GameList.title}
                </h2>
                <a
                    href={GameList.url}
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    {GameList.button}
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    {splitIntoChunks(GameList.gameList, 3).map((chunk, chunkIndex) => (
                        <div key={`chunk-${chunkIndex}`} className='flex flex-col shrink-0 md:basis-[31.8%] basis-10/12'>
                            {chunk.map((game, gameIndex) => (
                                <React.Fragment key={`game-${chunkIndex}-${gameIndex}`}>
                                    <a
                                        href={game.url}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        <div className='flex gap-4'>
                                            <img
                                                src={game.image}
                                                alt="game thumbnail"
                                                className='w-[100px] h-[100px] rounded-xl'
                                            />
                                            <div className='flex flex-col gap-2'>
                                                <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-[17.6px] m-0 truncate-1-lines'>
                                                    {game.title}
                                                </h3>
                                                <p className='text-custom-23 dark:text-custom-20 text-sm font-inter font-normal leading-[17.6px] m-0 truncate-2-lines'>
                                                    {game.text}
                                                </p>
                                                <p className='text-custom-13 w-16 text-sm text-center font-inter font-bold leading-[15.9px] border border-custom-13 border-solid rounded-xl py-1.5 px-4 m-0'>
                                                    {game.button}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                    {gameIndex !== 2 &&
                                        <hr className="bg-custom-29 dark:bg-custom-28 border-0 h-0.5 m-0 md:my-6 my-4" />
                                    }
                                </React.Fragment>
                            ))}
                        </div>
                    ))}
                </div>

                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default LargeIconGamesSection
