import React, { useEffect } from "react";
import { useMediaQuery } from 'react-responsive';
import TextContent from "./TextContent";
import Labels from "./Labels";
import CasinoGames from "./CasinoGames";
import NewsSection from "./NewsSection";
import Banner from "./Banner";
import {
  Web3GameList,
  BCGamesList,
  NewsContent,
  Web3GamesOfMonth,
  PopularThisWeek,
  AdventureGames,
  PVPGames,
  CardGames,
  ActionGames,
  ExploreMoreGames,
  TableGames,
  LiveCasinoGames
} from "../helpers/data";
import { playToEarnGames } from "../helpers/playToEarnGamesData";
import { useDispatch } from "react-redux";
import { fetchGames } from "../store/gamesSlice";
import { fetchEarnAllianceGames } from '../store/earnAllianceSlice';
// import { fetchPlayToEarnGames } from "../store/playToEarnSlice";
import ReviewBanner from "./ReviewBanner";
import TermsAndCondition from "./TermsAndCondition";
import GameNewsLetter from "./GameNewsLetter";
import Categories from "./Categories";
import GamesSection from "./GamesSection";
import LargeIconGamesSection from "./LargeIconGamesSection";
import LargeIconGamesPlayToEarnSection from "./LargeIconGamesPlayToEarnSection";
import SmallIconGamesPlayToEarnSection from "./SmallIconGamesPlayToEarnSection";
import MultipleGamesPlayToEarnSection from "./MultipleGamesPlayToEarnSection";
import MultipleGamesHrznSection from "./MultipleGamesHrznSection";
import FAQ from "./FAQ";

const Home = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    dispatch(fetchGames());
    dispatch(fetchEarnAllianceGames());
    // dispatch(fetchPlayToEarnGames());
  }, [dispatch]);

  // const gameList = useSelector((state) => state.playToEarnGames.games);
  // console.log(gameList, '@@@@@@@@ gameList @@@@@@@@');

  function getMonthlySeed() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    return `${year}${month}`;
  }

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }
  
  function getWeeklySeed() {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const week = getWeekNumber(now);
    return `${year}${week}${month}`;
  }

  function seededShuffle(games, seed) {
    let shuffled = games.slice();
    for (let i = shuffled.length - 1; i > 0; i--) {
      seed = (seed * 9301 + 49297) % 233280; // Linear congruential generator
      let j = Math.floor((seed / 233280) * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  function getRandomGames(games, gamesCount) {
    if (!Array.isArray(games) || typeof gamesCount !== 'number') {
      throw new Error('Invalid parameters');
    }

    const shuffledGames = [...games].sort(() => Math.random() - 0.5);
    return shuffledGames.slice(0, Math.min(gamesCount, games.length));
  }

  function getGamesByGenre(games, genre, gamesCount) {
    if (!Array.isArray(games) || typeof genre !== 'string' || typeof gamesCount !== 'number') {
        throw new Error('Invalid parameters');
    }

    const filteredGames = games.filter(game => game.genre_items.includes(genre));
    const shuffledGames = [...filteredGames].sort(() => Math.random() - 0.5);
    return shuffledGames.slice(0, Math.min(gamesCount, filteredGames.length));
  }

  function getRandomGamesUsingSeed(games, gamesCount, isWeekly = false) {
    if (!Array.isArray(games) || typeof gamesCount !== 'number') {
      throw new Error('Invalid parameters');
    }

    const seed = isWeekly ? getWeeklySeed() : getMonthlySeed();
    const shuffledGames = seededShuffle(games, seed);
    return shuffledGames.slice(0, Math.min(gamesCount, games.length));
  }

  return (
    <React.Fragment>
      <TextContent />
      <GameNewsLetter />
      <Banner />
      <hr className="bg-custom-29 dark:bg-custom-28 border-0 h-0.5 m-0 md:mt-[50px] mt-8" />
      <Labels />
      <GamesSection GameList={BCGamesList} marginTop={40} />
      <LargeIconGamesPlayToEarnSection GameList={getRandomGamesUsingSeed(playToEarnGames.items, 9, false)} Content={Web3GamesOfMonth} />
      <ReviewBanner />
      <SmallIconGamesPlayToEarnSection GameList={getRandomGamesUsingSeed(playToEarnGames.items, 9, true)} Content={PopularThisWeek} />
      <div className="flex md:flex-row flex-col lg:gap-12 md:gap-4 gap-16 w-full mt-16">
        <MultipleGamesPlayToEarnSection GameList={getGamesByGenre(playToEarnGames.items, "Adventure", isMobile ? 6 : 3)} Content={AdventureGames} />
        <MultipleGamesPlayToEarnSection GameList={getGamesByGenre(playToEarnGames.items, "PVP", isMobile ? 6 : 3)} Content={PVPGames} />
      </div>
      <div className="flex md:flex-row flex-col lg:gap-12 md:gap-4 gap-16 w-full mt-16">
        <MultipleGamesPlayToEarnSection GameList={getGamesByGenre(playToEarnGames.items, "PVP", isMobile ? 6 : 3)} Content={CardGames} />
        <MultipleGamesPlayToEarnSection GameList={getGamesByGenre(playToEarnGames.items, "Action", isMobile ? 6 : 3)} Content={ActionGames} />
      </div>
      <iframe
        title="bc games"
        src="https://media.aso1.net/js/ifr.html#id=131829"
        width="100%" height="640" align="center" frameBorder="0" hspace="0" vspace="0" marginHeight="0" marginWidth="0" scrolling="no"
        className="lg:mt-20 mt-16 w-full xl:h-[320px] h-full overflow-hidden border-none rounded-xl"
      />
      <Categories />
      <LargeIconGamesSection GameList={Web3GameList} />
      <CasinoGames
        title="Slots"
        categoryId="3"
        sectionId="slotsGames"
        button="View All"
        gameButton="Play Now"
        url="https://srt.bitcoin.com/games-app"
      />
      <NewsSection
        newsContent={NewsContent}
      />
      <div className="flex md:flex-row flex-col lg:gap-12 md:gap-4 gap-16 w-full mt-16">
        <MultipleGamesHrznSection Content={TableGames} />
        <MultipleGamesHrznSection Content={LiveCasinoGames} />
      </div>
      <SmallIconGamesPlayToEarnSection GameList={getRandomGames(playToEarnGames.items, 9)} Content={ExploreMoreGames} />
      <FAQ />
      <TermsAndCondition />
    </React.Fragment>
  );
};

export default Home;
