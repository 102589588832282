import React from "react";

export const MoonIcon = ({ color = "currentColor", size = 20, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 15.4661C5.90278 15.4661 4.12833 14.7403 2.67667 13.2886C1.22556 11.8375 0.5 10.0634 0.5 7.96615C0.5 6.04948 1.125 4.38615 2.375 2.97615C3.625 1.5667 5.22222 0.743924 7.16667 0.507812C7.51389 0.466146 7.78472 0.591146 7.97917 0.882813C8.17361 1.17448 8.16667 1.48003 7.95833 1.79948C7.72222 2.16059 7.545 2.54253 7.42667 2.94531C7.30889 3.34809 7.25 3.7717 7.25 4.21615C7.25 5.46615 7.6875 6.52865 8.5625 7.40365C9.4375 8.27865 10.5 8.71615 11.75 8.71615C12.1806 8.71615 12.6078 8.65365 13.0317 8.52865C13.455 8.40365 13.8333 8.23004 14.1667 8.00781C14.4583 7.81337 14.7569 7.80281 15.0625 7.97615C15.3681 8.15004 15.5 8.42448 15.4583 8.79948C15.2639 10.7161 14.4481 12.3064 13.0108 13.5703C11.5731 14.8342 9.90278 15.4661 8 15.4661Z"
        fill={color}
      />
    </svg>
  );
};
