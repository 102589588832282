import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Banners } from '../helpers/data';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import '../styles/carousel.css';

const Banner = () => {

    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const countryCode = useSelector(state => state.countryCode.value);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    return (
        <div className="relative cursor-pointer"
            onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
            onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
        >
            <div className='flex md:gap-6 gap-4 lg:mt-10 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                { Banners.map((banner, bannerIndex) => (
                    <a
                        key={`banner-${bannerIndex}`}
                        href={ countryCode === 'US' && bannerIndex === 1 ? banner.urlUS : banner.url }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <div className={`relative bg-custom-21 dark:bg-custom-22 flex flex-col justify-start flex-item rounded-xl xl:w-[324px] md:w-[244px] w-[240px] h-full`}>
                            {banner.isSponsored && (
                                <div className="absolute bg-custom-24 text-custom-4 text-sm leading-[15.4px] font-inter font-semibold rounded-tr-xl rounded-bl-lg p-2 top-0 right-0">
                                    Sponsored
                                </div>
                            )}
                            <img
                                src={ banner.bannerImage }
                                alt="Banner"
                                className='rounded-t-xl block'
                                width={ isMobile ? 240 : isTablet ? 244 : 324 }
                                height={ isMobile ? 140 : isTablet ? 140 : 190 }
                            />
                            <div className='flex flex-col h-full justify-between px-4 py-6'>
                                <div className='flex flex-col gap-2'>
                                    <h3 className='capitalize text-custom-4 dark:text-white xl:text-2xl md:text-lg text-xl font-inter font-semibold xl:leading-8 md:leading-normal leading-[26px] m-0'>
                                        { banner.heading }
                                    </h3>
                                    <p className={`${banner.isSponsored ? 'text-custom-4 dark:text-custom-20' : 'text-custom-23'} xl:text-base md:text-sm font-normal leading-[17.6px] m-0`}>
                                        { banner.text }
                                    </p>
                                </div>
                                <div className='bg-primary text-white text-base font-bold leading-[17.6px] w-fit text-center rounded-xl py-3 px-4 m-0 mt-4'>
                                    { banner.button }
                                </div>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            { !isTablet && showLeftArrow && (
                <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                    <SlArrowLeft color='#FFFFFF' />
                </button>
            )}
            { !isTablet && showRightArrow && (
                <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                    <SlArrowRight color='#FFFFFF' />
                </button>
            )}
        </div>
    )
}

export default Banner
