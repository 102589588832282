import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CategoriesList } from '../helpers/data';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const Categories = () => {

    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    return (
        <div id='categoriesSection' className='xl:mt-20 lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                    {CategoriesList.title}
                </h2>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-6 gap-4 xl:mt-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { CategoriesList.list.map((category, categoryIndex) => (
                        <a
                            key={categoryIndex}
                            href={category.url}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className={`${category.bgColor} flex flex-col justify-between flex-item gap-4 rounded-2xl md:w-32 w-24 p-4 h-[calc(100%-32px)]`}>
                                <img
                                    src={category.icon}
                                    alt={category.altText}
                                    className='w-8 h-8'
                                />
                                <h3 className='text-white text-sm font-inter font-semibold leading-[15.4px] m-0'>{category.text}</h3>
                            </div>
                        </a>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default Categories
