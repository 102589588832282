export const textContent = {
    title: 'Explore Top Bitcoin & Crypto Games',
    text: 'Navigate the exciting realm of blockchain games',
};

export const labels = [
    {
        icon: '/images/icons/slot-machine.svg',
        link: 'bcGames',
        altText: 'slot',
        text: 'BC Games',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/web3-game.svg',
        link: 'web3GamesOfMonth',
        altText: 'web3 game',
        text: 'Web3 Games Of Month',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/news.svg',
        link: 'reviewSection',
        altText: 'newsIcon',
        text: 'Review',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/popular-new.svg',
        link: 'popularThisWeek',
        altText: 'popular',
        text: 'Popular This Week',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/recommended.svg',
        link: 'adventureGames',
        altText: 'recommended',
        text: 'Adventure',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/dice.svg',
        link: 'pvpGames',
        altText: 'tableGame',
        text: 'PVP',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/casino.svg',
        link: 'cardGames',
        altText: 'liveCasino',
        text: 'Card',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/popular-new.svg',
        link: 'actionGames',
        altText: 'popular',
        text: 'Action',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/star.svg',
        link: 'categoriesSection',
        altText: 'star',
        text: 'Categories',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/web3-game.svg',
        link: 'web3Games',
        altText: 'web3 game',
        text: 'Web3',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/slot-machine.svg',
        link: 'slotsGames',
        altText: 'slot',
        text: 'Slots',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/news.svg',
        link: 'newsSection',
        altText: 'newsIcon',
        text: 'News',
        width: '24',
        height: '24',
    },
    {
        icon: '/images/icons/dice.svg',
        link: 'tableGames',
        altText: 'tableGame',
        text: 'Table',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/casino.svg',
        link: 'liveCasinoGames',
        altText: 'liveCasino',
        text: 'Live Casino',
        width: '20',
        height: '20',
    },
    {
        icon: '/images/icons/basketball.svg',
        link: 'exploreMoreGames',
        altText: 'basketball',
        text: 'Explore More',
        width: '20',
        height: '20',
    }
];

export const verseGameList = [
    {
        image: '/images/verse-scratch-win.webp',
        altText: 'web game icon',
        highlightText: 'SCRATCH & WIN',
        heading: 'Verse Scratcher',
        text: 'Purchase a ticket and uncover three matching numbers to win VERSE.',
        url: 'https://srt.bitcoin.com/games-scratch',
    },
    {
        image: '/images/verse-clicker.webp',
        altText: 'web game icon',
        highlightText: 'ONE MORE COOKIE',
        heading: 'Verse Clicker',
        text: 'Tap your way to the top with Verse points and ascend the leaderboard! Become part of the Verse community and immerse yourself in non-stop clicking excitement.',
        url: 'https://srt.bitcoin.com/games-clicker',
    },
    {
        image: '/images/verse-voyagers.webp',
        altText: 'web game icon',
        highlightText: 'GET YOUR NFT ON',
        heading: 'Verse Voyagers',
        text: 'Turn the virtual slot machine to create your distinct Voyager. With over 160 million combinations at your disposal, the potential is limitless!',
        url: 'https://srt.bitcoin.com/games-voyager',
    },
    {
        image: '/images/verse-ecosystem.webp',
        altText: 'web game icon',
        highlightText: 'EXPLORE MORE',
        heading: 'Verse Ecosystem',
        text: "VERSE is Bitcoin.com's ecosystem token and the world's gateway to democratized finance",
        url: 'https://srt.bitcoin.com/games-verse',
    },
];

export const Banners = [
    {
        bannerImage: "/images/bcgames-banner.webp",
        heading: "BC Game",
        backgroundColor: "bg-custom-lightGreen",
        text: "360% Deposit Bonus up to $100,000 + No KYC & No Withdrawal Limits",
        button: "Play Now",
        url: "https://srt.bitcoin.com/bc-games-bcom",
        isSponsored: true,
    },
    {
        bannerImage: "/images/games-casino-banner.webp",
        heading: "Games Casino",
        backgroundColor: "bg-custom-lightGreen",
        text: "Welcome Offer: Deposit at least $2,000 to get 25% cashback on all slot games you play for the next 14 days!",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-app",
        isSponsored: false,
    },
    {
        bannerImage: "/images/verse-scratch-wins-banner.webp",
        heading: "Verse Scratcher",
        backgroundColor: "bg-primary",
        text: "Stand a chance to win the VERSE Jackpot with every scratch ticket",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-scratch",
        isSponsored: false,
    },
    {
        bannerImage: "/images/video-casino-banner.webp",
        heading: "Bitcoin Video Casino",
        backgroundColor: "bg-custom-15",
        text: "Play Bitcoin Slots. No registration needed, and you can start playing immediately!",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-bvc",
        isSponsored: false,
    },
    {
        bannerImage: "/images/satoshi-dice-banner.webp",
        heading: "Satoshi Dice Dapp",
        backgroundColor: "bg-custom-darkGreen",
        text: "Known for its simplicity, transparency, and instant payouts",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-sd",
        isSponsored: false,
    },
    {
        bannerImage: "/images/verse-clicker-banner.webp",
        heading: "Verse Clicker",
        backgroundColor: "bg-primary",
        text: "Join the addictive world of Verse Clicker, where every click earns you Verse Points.",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-clicker",
        isSponsored: false,
    },
    {
        bannerImage: "/images/verse-voyagers-banner.webp",
        heading: "Verse Voyagers",
        backgroundColor: "bg-primary",
        text: "A collection of 10,000 algorithmically generated unique space farers",
        button: "Play Now",
        url: "https://srt.bitcoin.com/games-voyager",
        isSponsored: false,
    },
];

export const PopularGameList = {
    heading: "Brought to you by Stake",
    games: [
        {
            icon: "/images/popular-games/2 Wild 2 Die.jpg",
            altText: "webgame",
            heading: "2 Wild 2 Die",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Play 2 Wild 2 Die, Wild West slot at Stake Casino.',
        },
        {
            icon: "/images/popular-games/Blackjack.jpg",
            altText: "webgame",
            heading: "Blackjack",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Play Blackjack with the lowest house edge online.',
        },
        {
            icon: "/images/popular-games/Cash Crew.jpg",
            altText: "webgame",
            heading: "Cash Crew",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Cash Crew: horror slot, high variance, big wins.',
        },
        {
            icon: "/images/popular-games/Crazy Time.jpg",
            altText: "webgame",
            heading: "Crazy Time",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Crazy Time: thrilling live casino game with bonuses.',
        },
        {
            icon: "/images/popular-games/Dice.jpg",
            altText: "webgame",
            heading: "Dice",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Dice: simple, customizable crypto casino game.',
        },
        {
            icon: "/images/popular-games/Fist Of Destruction.jpg",
            altText: "webgame",
            heading: "Fist Of Destruction",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Street brawl slot, high volatility, big wins online.',
        },
        {
            icon: "/images/popular-games/Miss Rainbow.jpg",
            altText: "webgame",
            heading: "Miss Rainbow",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Crazy Time: thrilling live casino game with bonuses.',
        },
        {
            icon: "/images/popular-games/Plinko.jpg",
            altText: "webgame",
            heading: "Plinko",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Plinko: drop ball, customize risk, win multipliers online.',
        },
        {
            icon: "/images/popular-games/Roulette.jpg",
            altText: "webgame",
            heading: "Roulette",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Super low house edge, high RTP, big win potential.',
        },
        {
            icon: "/images/popular-games/Samurai Dogs.jpg",
            altText: "webgame",
            heading: "Samurai Dogs",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Samurai Dogs, ninja canine slot exclusively at Stake.',
        },
        {
            icon: "/images/popular-games/Underworld.jpg",
            altText: "webgame",
            heading: "Underworld",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Experience horror-themed slot with cascades feature.',
        },
        {
            icon: "/images/popular-games/Wanted Dead or a Wild.jpg",
            altText: "webgame",
            heading: "Wanted Dead or a Wild",
            countryCode: 'Other',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer',
            text: 'Gritty cowboy slot, high variance, big prizes online.',
        },
        {
            icon: "/images/popular-games/us/Aztec Powernudge.jpg",
            altText: "webgame",
            heading: "Aztec Powernudge",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Venture into the jungle, uncover Aztec treasures.',
        },
        {
            icon: "/images/popular-games/us/Baccarat.jpg",
            altText: "webgame",
            heading: "Baccarat",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Baccarat with exhilarating options at Stake.us.',
        },
        {
            icon: "/images/popular-games/us/Blackjack.jpg",
            altText: "webgame",
            heading: "Blackjack",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Play Blackjack with the lowest house edge online.',
        },
        {
            icon: "/images/popular-games/us/Floating Dragon.jpg",
            altText: "webgame",
            heading: "Floating Dragon",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Explore Oriental riches with Floating Dragon at Stake.',
        },
        {
            icon: "/images/popular-games/us/Multiplier Mine.jpg",
            altText: "webgame",
            heading: "Multiplier Mine",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Join popular game inspired by classic Minesweeper.',
        },
        {
            icon: "/images/popular-games/us/Roulette.jpg",
            altText: "webgame",
            heading: "Roulette",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Super low house edge, high RTP, big win potential.',
        },
        {
            icon: "/images/popular-games/us/Stake Poker.jpg",
            altText: "webgame",
            heading: "Stake Poker",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Enjoy ultimate poker night with friends at Stake.us.',
        },
        {
            icon: "/images/popular-games/us/Sugar Rush.jpg",
            altText: "webgame",
            heading: "Sugar Rush",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Enjoy candy-themed slot with big multipliers at Stake.',
        },
        {
            icon: "/images/popular-games/us/Sweet Bonanza.jpg",
            altText: "webgame",
            heading: "Sweet Bonanza",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Indulge in candy-themed slot with big multipliers.',
        },
        {
            icon: "/images/popular-games/us/Tome of Life.jpg",
            altText: "webgame",
            heading: "Tome of Life",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Explore enchanted forest for wins at Stake social casino.',
        },
        {
            icon: "/images/popular-games/us/Tyrants Fall.jpg",
            altText: "webgame",
            heading: "Tyrants Fall",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Tyrants in this thrilling gameplay experience.',
        },
        {
            icon: "/images/popular-games/us/Wanted Dead or a Wild.jpg",
            altText: "webgame",
            heading: "Wanted Dead or a Wild",
            countryCode: 'US',
            category: 'Top Picks',
            url: 'https://srt.bitcoin.com/stake-games-footer-us',
            text: 'Gritty cowboy slot, high variance, big prizes online.',
        },
    ],
};

export const FooterList = [
    {
        title: "Support",
        links: [
            {
                name: "F.A.Q",
                url: "faqSection",
                externalLink: true,
                idUrl: true,
            },
            {
                name: "VIP",
                url: "https://srt.bitcoin.com/games-app-vip",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Provably Fair",
                url: "https://srt.bitcoin.com/games-app-pf",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Advertise",
                url: "mailto:affiliates@bitcoin.com?subject=Games Review Request",
                externalLink: false,
                idUrl: false,
            },
            {
                name: "Affiliate Program",
                url: "https://srt.bitcoin.com/games-aff",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Terms of Service",
                url: "https://srt.bitcoin.com/games-app-tos",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Privacy Policy",
                url: "https://srt.bitcoin.com/games-app-priv",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Buy",
                url: "https://srt.bitcoin.com/games-buy",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Sell",
                url: "https://srt.bitcoin.com/games-sell",
                externalLink: true,
                idUrl: false,
            },
        ],
    },
    {
        title: "VERSE Games",
        links: [
            {
                name: "Verse Scratcher",
                url: "https://srt.bitcoin.com/games-scratch",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Verse Clicker",
                url: "https://srt.bitcoin.com/games-clicker",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Verse Voyagers",
                url: "https://srt.bitcoin.com/games-voyager",
                externalLink: true,
                idUrl: false,
            },
        ],
    },
    {
        title: "Casino",
        links: [
            {
                name: "Slots",
                url: "https://srt.bitcoin.com/games-app",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Play At BC Game",
                url: "https://srt.bitcoin.com/bc-games-bcom",
                externalLink: true,
                idUrl: false,
            },
            {
                name: "Gambling",
                url: "https://srt.bitcoin.com/games-gam",
                externalLink: true,
                idUrl: false,
            },
        ],
    },
];

export const SocialMediaList = {
    title: "Social networks",
    socialMedia : [
        // {
        //     icon: "/images/facebook.svg",
        //     darkIcon: "/images/facebook.svg",
        //     altText: "Facebook",
        //     url: "https://srt.bitcoin.com/games-fb",
        // },
        {
            icon: "/images/telegram.svg",
            darkIcon: "/images/telegram.svg",
            altText: "Telegram",
            url: "https://srt.bitcoin.com/games-tg",
        },
        {
            icon: "/images/twitter.svg",
            darkIcon: "/images/twitter-dark.svg",
            altText: "Twitter",
            url: "https://srt.bitcoin.com/games-x",
        },
    ],
};

export const Web3GameList = {
    title: "Web3",
    button: "View All",
    url: "/",
    sectionId: "web3Games",
    gameList: [
        {
            image: '/images/up-or-down.webp',
            title: 'Up or Down',
            text: "Test your instincts! Predict whether Bitcoin's price will go UP or DOWN within a set timeframe and win big!",
            button: "Play Now",
            url: 'https://games.bitcoin.com/up-or-down',
        },
        {
            image: '/images/axie-infinity.webp',
            title: 'Axie Infinity',
            text: "Unleash your strategic skills and earn rewards in the ultimate play-to-earn adventure with Axie Infinity!",
            button: "Play Now",
            url: 'https://axieinfinity.com/',
        },
        {
            image: '/images/sunflower-land.webp',
            title: 'Sunflower Land',
            text: "Grow your farm, craft unique items, and explore the endless possibilities of Sunflower Land's play-to-own experience!",
            button: "Play Now",
            url: 'https://sunflower-land.com/',
        },
        {
            image: '/images/zed-run.webp',
            title: 'Zed Run',
            text: "Build your virtual stable of champions and use your skill to dominate on the racetrack against global competitors for big rewards.",
            button: "Play Now",
            url: 'https://zed.run/',
        },
        {
            image: '/images/gods-unchained.webp',
            title: 'Gods Unchained',
            text: "The trading card game where every card and victory is truly yours.",
            button: "Play Now",
            url: 'https://godsunchained.com/',
        },
        {
            image: '/images/star-atlas.webp',
            title: 'Star Atlas',
            text: "A grand strategy game of space exploration, territorial conquest, political domination, and more.",
            button: "Play Now",
            url: 'https://staratlas.com/',
        },
        {
            image: '/images/ev-io.webp',
            title: 'EV.IO',
            text: "Jump into the action-packed world of Ev.io—a futuristic FPS where skill and strategy reign supreme.",
            button: "Play Now",
            url: 'https://ev.io/',
        },
        {
            image: '/images/rage-effect.webp',
            title: 'Rage Effect',
            text: "In Rage Effect, you are not limited to what we create. Join worlds created by our players, for players, and enjoy in our community built content.",
            button: "Play Now",
            url: 'https://www.rageeffect.io/',
        },
        {
            image: '/images/project-seed.webp',
            title: 'Project Seed',
            text: "Battle, explore, and craft your way through a vibrant universe where your efforts are rewarded in the play-to-earn economy.",
            button: "Play Now",
            url: 'https://www.projectseed.com/game',
        },
    ]
};

export const ReviewBannerList = {
    title: "Reviews",
    text: "Explore an extensive directory tailored for Bitcoin and Crypto enthusiasts seeking seamless access to the best gambling services.",
    button: "Discover now",
    url: "https://www.bitcoin.com/directory/",
    viewButton: 'View all',
    desktopBannerList: [
        {
            icon: '/images/icons/slot-machine.svg',
            link: 'https://www.bitcoin.com/gambling/',
            altText: 'slot',
            text: 'Gambling',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/crypto-casino/',
            altText: 'bitcoin',
            text: 'Crypto Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/vip/',
            altText: 'star',
            text: 'VIP Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/online-casino/',
            altText: 'liveCasino',
            text: 'Online Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/nft.svg',
            link: 'https://www.bitcoin.com/web3-casino/',
            altText: 'nft',
            text: 'Web3 Casino',
            width: '17',
            height: '20',
        },
        {
            icon: '/images/icons/dice.svg',
            link: 'https://www.bitcoin.com/gambling/casino/slots/',
            altText: 'dice',
            text: 'Slots Casino',
            width: '20',
            height: '20',
        },
        {
            icon: '/images/icons/popular.svg',
            link: 'https://www.bitcoin.com/gambling/casino/jackpot/',
            altText: 'popular',
            text: 'Jackpot Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/ethereum.svg',
            link: 'https://www.bitcoin.com/gambling/casino/ethereum/',
            altText: 'ethereum',
            text: 'Ethereum Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/gambling/casino/instant-withdrawal/',
            altText: 'bitcoin',
            text: 'Instant Withdrawal',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/casino.svg',
            link: 'https://www.bitcoin.com/altcoin-casino/',
            altText: 'casino',
            text: 'Altcoin Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/baccarat/',
            altText: 'star',
            text: 'Baccarat',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/gambling/casino/provably-fair/',
            altText: 'liveCasino',
            text: 'Provably Fair',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/casino.svg',
            link: 'https://www.bitcoin.com/card-casino/',
            altText: 'casino',
            text: 'Card Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/dice.svg',
            link: 'https://www.bitcoin.com/table-games/',
            altText: 'dice',
            text: 'Table Games',
            width: '20',
            height: '20',
        },
        {
            icon: '/images/icons/popular.svg',
            link: 'https://www.bitcoin.com/gambling/casino/roulette/',
            altText: 'popular',
            text: 'Roulette',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/ethereum.svg',
            link: 'https://www.bitcoin.com/gambling/casino/eth-live-dealer/',
            altText: 'ethereum',
            text: 'Eth Live Dealer',
            width: '24',
            height: '24',
        },
    ],
    tabletBannerList: [
        {
            icon: '/images/icons/slot-machine.svg',
            link: 'https://www.bitcoin.com/gambling/',
            altText: 'slot',
            text: 'Gambling',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/crypto-casino/',
            altText: 'bitcoin',
            text: 'Crypto Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/vip/',
            altText: 'star',
            text: 'VIP Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/online-casino/',
            altText: 'liveCasino',
            text: 'Online Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/nft.svg',
            link: 'https://www.bitcoin.com/web3-casino/',
            altText: 'nft',
            text: 'Web3 Casino',
            width: '17',
            height: '20',
        },
        {
            icon: '/images/icons/dice.svg',
            link: 'https://www.bitcoin.com/gambling/casino/slots/',
            altText: 'dice',
            text: 'Slots Casino',
            width: '20',
            height: '20',
        },
        {
            icon: '/images/icons/popular.svg',
            link: 'https://www.bitcoin.com/gambling/casino/jackpot/',
            altText: 'popular',
            text: 'Jackpot Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/ethereum.svg',
            link: 'https://www.bitcoin.com/gambling/casino/ethereum/',
            altText: 'ethereum',
            text: 'Ethereum Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/gambling/casino/instant-withdrawal/',
            altText: 'bitcoin',
            text: 'Instant Withdrawal',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/casino.svg',
            link: 'https://www.bitcoin.com/altcoin-casino/',
            altText: 'casino',
            text: 'Altcoin Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/baccarat/',
            altText: 'star',
            text: 'Baccarat',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/gambling/casino/provably-fair/',
            altText: 'liveCasino',
            text: 'Provably Fair',
            width: '24',
            height: '24',
        },
    ],
    mobileBannerList: [
        {
            icon: '/images/icons/slot-machine.svg',
            link: 'https://www.bitcoin.com/gambling/',
            altText: 'slot',
            text: 'Gambling',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/bitcoin.svg',
            link: 'https://www.bitcoin.com/crypto-casino/',
            altText: 'bitcoin',
            text: 'Crypto Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/star.svg',
            link: 'https://www.bitcoin.com/gambling/casino/vip/',
            altText: 'star',
            text: 'VIP Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/basketball.svg',
            link: 'https://www.bitcoin.com/online-casino/',
            altText: 'liveCasino',
            text: 'Online Casino',
            width: '24',
            height: '24',
        },
        {
            icon: '/images/icons/nft.svg',
            link: 'https://www.bitcoin.com/web3-casino/',
            altText: 'nft',
            text: 'Web3 Casino',
            width: '17',
            height: '20',
        },
    ]
}

export const CategoriesList = {
    title: "Categories",
    list: [
        {
            icon: "/images/icons/thumb-up-icon.svg",
            altText: "thumb up",
            text: "Adventure",
            bgColor: "caterogy-bg-color-1",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-adventure"
        },
        {
            icon: "/images/icons/bitcoin-icon.svg",
            altText: "bitcoin",
            text: "Card",
            bgColor: "caterogy-bg-color-2",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-card"
        },
        {
            icon: "/images/icons/basketball-icon.svg",
            altText: "basketball",
            text: "Virtual World",
            bgColor: "caterogy-bg-color-1",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-virtual-world"
        },
        {
            icon: "/images/icons/slot-machine-icon.svg",
            altText: "slot",
            text: "Gambling",
            bgColor: "caterogy-bg-color-4",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-gambling"
        },
        {
            icon: "/images/icons/ethereum-icon.svg",
            altText: "ethereum",
            text: "Mining",
            bgColor: "caterogy-bg-color-2",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-mining"
        },
        {
            icon: "/images/icons/thumb-up-icon.svg",
            altText: "thumb up",
            text: "PVP",
            bgColor: "caterogy-bg-color-3",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-pvp"
        },
        {
            icon: "/images/icons/star-icon.svg",
            altText: "star",
            text: "Sci Fi",
            bgColor: "caterogy-bg-color-1",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-sci-fi"
        },
        {
            icon: "/images/icons/basketball-icon.svg",
            altText: "basketball",
            text: "Strategy",
            bgColor: "caterogy-bg-color-3",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-strategy"
        },
        {
            icon: "/images/icons/thumb-up-icon.svg",
            altText: "thumb up",
            text: "Action",
            bgColor: "caterogy-bg-color-1",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-action"
        },
        {
            icon: "/images/icons/bitcoin-icon.svg",
            altText: "bitcoin",
            text: "Tower Defense",
            bgColor: "caterogy-bg-color-2",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-tower-defense"
        },
        {
            icon: "/images/icons/basketball-icon.svg",
            altText: "basketball",
            text: "Open World",
            bgColor: "caterogy-bg-color-4",
            url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-open-world"
        },
    ]
}

export const BlockchainGamesList = {
    title: "Top Blockchain Games",
    button: "View All",
    url: "/",
    imgUrl: false,
    imgUrlLight: "",
    imgUrlDark: "",
    altText: "",
    gameList: [
        {
            image: "./images/blockchain-game-1.webp",
            title: "Ankr SDK",
            text: "Introducing a cutting-edge toolkit for game developers in the web3",
            button: "Play Now",
            url: "/",
        },
        {
            image: "./images/blockchain-game-2.webp",
            title: "Dice",
            text: "Simple, customizable crypto casino game.",
            button: "Play Now",
            url: "/",
        },
        {
            image: "./images/blockchain-game-3.webp",
            title: "Magic Eden",
            text: "Magic Eden is an innovative NFT marketplace built on the Solana blockchain",
            button: "Play Now",
            url: "/",
        },
        {
            image: "./images/blockchain-game-4.webp",
            title: "Planet IX",
            text: "Planet IX stands as the preeminent GameFi platform within the expansive realm...",
            button: "Play Now",
            url: "/",
        },
        {
            image: "./images/blockchain-game-5.webp",
            title: "SweatCoin",
            text: "Introducing a groundbreaking concept that revolutionizes the way we earn",
            button: "Play Now",
            url: "/",
        }
    ]
}

export const BCGamesList = {
    title: "",
    button: "View All",
    url: "https://srt.bitcoin.com/bc-games-bcom",
    imgUrl: true,
    imgUrlLight: "./images/bcgames-logo.svg",
    imgUrlDark: "./images/bcgames-logo-dark.svg",
    altText: "bcgames",
    sectionId: "bcGames",
    gameList: [
        {
            image: "./images/crash.webp",
            title: "Crash",
            text: "Bet and cash out before the multiplier crashes for big wins.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/plinko.webp",
            title: "Plinko",
            text: "Drop balls into a grid and win multipliers based on where they land.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/dice.webp",
            title: "Classic Dice",
            text: "Choose safe paths to climb the tower, avoiding hidden traps.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/tower.webp",
            title: "Tower Legend",
            text: "Choose safe paths to climb the tower, avoiding hidden traps.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/limbo.webp",
            title: "Limbo",
            text: "Predict the multiplier and cash out before the rocket stops climbing.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/baccarat.webp",
            title: "Baccarat",
            text: "Place your bets on Player, Banker, or Tie and predict who gets closer to 9.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/keno.webp",
            title: "Keno",
            text: "Choose your lucky numbers and watch the draw unfold for a chance to win big.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/hilo.webp",
            title: "Hilo",
            text: "Guess if the next card is higher or lower and multiply your winnings.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/roulette.webp",
            title: "Roulette",
            text: "Spin the wheel and let the ball decide your lucky number or color.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/mine.webp",
            title: "Mine",
            text: "Navigate the grid, avoid hidden mines, and uncover treasures.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/video-poker.webp",
            title: "Video Poker",
            text: "Play your best hand, hold or draw cards, and aim for the top payout.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/wheel.webp",
            title: "Wheel",
            text: "Spin the wheel and stop on your chosen multiplier or prize.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        },
        {
            image: "./images/twist.webp",
            title: "Twist",
            text: "Predict the multiplier, time your cash-out, and beat the odds before the twist takes over.",
            button: "Play Now",
            url: "https://srt.bitcoin.com/bc-games-bcom",
        }
    ]
}

export const NewsContent = {
    title: "",
    button: "View All",
    url: "https://srt.bitcoin.com/games-news",
    imgUrl: true,
    imgUrlLight: "./images/news-logo.svg",
    imgUrlDark: "./images/news-logo-dark.svg",
    altText: "news logo",
    readButton: "Read more",
    podcastUrl: "https://www.youtube.com/playlist?list=PLL-mmY61Sru9VqAEBO49LGGYiiefBPQXl",
    playButtonImgUrl: "./images/play-button.svg",
    podcastTitle: "Catch Up on the Latest in Crypto!!",
    podcastText: "Bitcoin.com - Official Channel",
}

export const Web3GamesOfMonth = {
    title: "Web3 Games of the month",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-web3-games-of-month-view-all",
    gameButton: "Play Now",
    sectionId: "web3GamesOfMonth",
}

export const PopularThisWeek = {
    title: "Popular this week",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-popular-this-week-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "popularThisWeek",
}

export const AdventureGames = {
    title: "Adventure",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-adventure-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "adventureGames",
}

export const PVPGames = {
    title: "PVP",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-pvp-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "pvpGames",
}

export const CardGames = {
    title: "Card",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-card-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "cardGames",
}

export const ActionGames = {
    title: "Action",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-action-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "actionGames",
}

export const ExploreMoreGames = {
    title: "Explore more",
    button: "View All",
    url: "https://srt.bitcoin.com/play-earn-game?utm_term=bcom-explore-more-view-all",
    gameButton: "Play Now",
    responsiveGameButton: "Play",
    sectionId: "exploreMoreGames",
}

export const TableGames = {
    title: "Table",
    button: "View All",
    sectionId: "tableGames",
    categoryId: "4",
    url: "https://srt.bitcoin.com/games-app",
    gameButton: "Play Now",
    responsiveGameButton: "Play"
}

export const LiveCasinoGames = {
    title: "Live Casino",
    button: "View All",
    sectionId: "liveCasinoGames",
    categoryId: "6",
    url: "https://srt.bitcoin.com/games-app",
    gameButton: "Play Now",
    responsiveGameButton: "Play"
}

export const FAQList = [
    {
        "question": "What is games.bitcoin.com?",
        "answer": "Games.bitcoin.com is an online platform that offers a wide range of casino and blockchain-based games where players can use cryptocurrencies like Bitcoin to play and win."
    },
    {
        "question": "What is Play-to-Earn (P2E)?",
        "answer": "Play-to-Earn (P2E) is a gaming model where players earn rewards, typically in the form of cryptocurrency or NFTs, by participating in games. These rewards can often be traded or sold for real-world value."
    },
    {
        "question": "Are Bitcoin and crypto games secure?",
        "answer": "Yes, Bitcoin and crypto games leverage blockchain technology to ensure security and fairness. However, it’s important to use reputable platforms and protect your crypto wallet credentials."
    },
    {
        "question": "Can I play crypto games without investing money?",
        "answer": "Yes, many Play-to-Earn games allow players to start for free or earn rewards by completing tasks or achieving milestones. However, some games may require an initial investment to unlock premium features."
    },
    {
        "question": "How do I earn cryptocurrency or NFTs in Play-to-Earn games?",
        "answer": "You can earn cryptocurrency or NFTs by completing in-game tasks, winning battles, participating in tournaments, or trading in-game assets. The specific earning mechanics depend on the game."
    },
    {
        "question": "What are the benefits of using blockchain in gaming?",
        "answer": "Blockchain enhances gaming by enabling true ownership of in-game assets, providing transparency in game mechanics, and allowing for decentralized economies where players can trade and sell assets."
    },
    {
        "question": "Are there risks involved in Play-to-Earn games?",
        "answer": "Yes, risks include potential losses from volatile cryptocurrency prices, scams, and investing in games that might fail. Always research a game and its developers before investing time or money."
    },
    {
        "question": "Does games.bitcoin.com offer customer support?",
        "answer": "Yes, games.bitcoin.com provides 24/7 customer support via live chat and email to assist with any queries or issues you may have."
    },
];
