import styled from 'styled-components';
import { media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const VideoContainer = styled.div`
  position: fixed;
  bottom: 75px;
  display: ${props => (props.isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 70%;
  max-width: 600px;
  height: auto;
  aspect-ratio: 16 / 9;
  right: 5%;
  z-index: 100000;
  max-width: 600px;

  ${media.md`
    position: fixed;
    width: 100%;
    height: 225px;
    align-items: unset;
    top: unset;
    bottom: 80px;
    right: 20px;
    width: auto;
    margin: 0;
    left: unset;
    max-width: 800px;
  `}

  ${media.lg`
    top: unset;
    bottom: 80px;
    width: 100%;
    height: 225px;
    align-items: unset;
    left: unset;
    right: 20px;
    width: auto;
    max-width: 1200px;
    height: auto;
  `}
`;

export const CloseButton = styled.img`
  position: absolute;
  top: 1px;
  right: 2px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: 4px;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  // background: white;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.3);
  }

  ${media.md`
    top: 6;
    right: 0;
  `}
`;

export const MuteUnmuteButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 10px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  padding: 0;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const PausePlayButton = styled.button`
  position: absolute;
  bottom: 12px;
  right: 40px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  z-index: 1;
  padding: 0;

  img {
    width: 15px;
    height: 15px;
  }
`;

export const NextVideo = styled.button`
  position: absolute;
  bottom: 12px;
  right: 100px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 0;

  img {
    width: 13px;
    height: 13px;
  }
`;

export const PreviousVideo = styled.button`
  position: absolute;
  bottom: 12px;
  right: 75px;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 0;
  img {
    width: 13px;
    height: 13px;
  }
`;

export const YoutubeDimension = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ButtonInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
