import React from 'react';
import { textContent } from '../helpers/data';

const TextContent = () => {
    return (
        <div className='text-center md:mt-16 mt-6'>
            <h1 className='text-custom-4 dark:text-white xl:text-[64px] md:text-5xl text-2xl font-inter font-semibold md:leading-[72px] leading-8 m-0'>
                { textContent.title }
            </h1>
            <p className='text-custom-2 dark:text-custom-20 xl:text-2xl md:text-xl text-sm font-inter font-normal leading-normal m-0 md:mt-4 mt-2'>
                { textContent.text }
            </p>
        </div>
    )
}

export default TextContent
