import React, { useRef, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';

const GamesSection = (props) => {

    const {
        GameList,
        marginTop,
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 800;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = () => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    };

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [theme]);

    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    const isDark = document.body.classList.contains('dark');
                    setTheme(isDark ? 'dark' : 'light');
                }
            });
        });
    
        observer.observe(document.body, {
            attributes: true
        });
    
        return () => observer.disconnect();
    }, []);

    return (
        <div id={GameList.sectionId} className={`${marginTop ? `mt-[40px]` : 'lg:mt-20 mt-16'}`}>
            <div className='flex justify-between items-center'>
                {GameList.imgUrl ? (
                    <img
                        src={theme === 'light' ? GameList.imgUrlLight : GameList.imgUrlDark}
                        alt={GameList.altText}
                    /> 
                ) : (
                    <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                        { GameList.title }
                    </h2>
                )}
                <a
                
                    href={ GameList.url }
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    { GameList.button }
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    { GameList.gameList.map((game, index) => (
                        <a 
                            key={`game-${index}`}
                            href={ game.url }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='flex flex-col bg-custom-21 dark:bg-custom-22 flex-item rounded-xl w-[243px] h-full'>
                                <img
                                    className='w-full rounded-t-xl'
                                    src={game.image}
                                    alt="game thumbnail"
                                />
                                <div className='flex flex-col justify-between h-full gap-4 py-6 px-4'>
                                    <div className='flex flex-col gap-2'>
                                        <h3 className='text-custom-4 dark:text-white text-xl font-inter font-semibold leading-normal m-0'>
                                            { game.title }
                                        </h3>
                                        <p className='text-custom-23 dark:text-custom-20 text-lg font-inter font-normal leading-normal m-0'>
                                            { game.text }
                                        </p>
                                    </div>
                                    <p className='text-custom-13 text-base text-center font-inter font-semibold leading-[17.6px] border border-custom-13 border-solid rounded-xl py-2 px-4 m-0'>
                                        {game.button}
                                    </p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default GamesSection
