import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import xml2js from 'xml2js';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import '../styles/carousel.css';
import YouTubeVideoPlayer from './YoutubeHomePage/YoutubeVideo.jsx';

const getDate = (date) => {
    const givenDate = new Date(date);
    const now = new Date();
    const timeDifference = now - givenDate;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (days > 0) {
        return `${days} ${days > 1 ? 'days' : 'day'} ago`;
    }

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    if (hours > 0) {
        return `${hours} ${hours > 1 ? 'hours' : 'hour'} ago`;
    }

    const minutes = Math.floor(timeDifference / (1000 * 60));
    return `${minutes} ${minutes > 1 ? 'minutes' : 'minute'} ago`;
};

const NewsSection = (props) => {

    const {
        newsContent
    } = props;
    const isTablet = useMediaQuery({ query: '(max-width: 1279px)' });
    const [newsItems, setNewsItems] = useState([]);
    const carouselRef = useRef(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");
    const iframeRef = useRef(null);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await axios.get('https://news.bitcoin.com/category/games/feed', {
                    headers: {'Accept': 'application/xml'}
                });

                const xml = response.data;
                xml2js.parseString(xml, { explicitArray: false }, (err, result) => {
                    if (err) {
                      throw err;
                    }
                    
                    const items = result.rss.channel.item.map(item => ({
                        ...item,
                        timeAgo: getDate(item.pubDate)
                    }));

                    setNewsItems(items);
                });
            } catch (error) {
                console.error("There was an error fetching the XML data: ", error);
            }
        };

        fetchNews();
        
    }, []);
  
    const scroll = (direction) => {
        if (carouselRef.current) {
            const scrollAmount = 600;
            const maxScrollLeft = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
            let newScrollPosition = carouselRef.current.scrollLeft + direction * scrollAmount;
            
            // Prevent scrolling beyond the content boundaries
            newScrollPosition = Math.max(0, Math.min(newScrollPosition, maxScrollLeft));
            carouselRef.current.scrollLeft = newScrollPosition;

            // Update arrow visibility based on the potential new position
            setShowLeftArrow(newScrollPosition > 0);
            setShowRightArrow(newScrollPosition < maxScrollLeft);
        }
    };

    const checkArrowsVisibility = useCallback(() => {
        if (carouselRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
        }
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', checkArrowsVisibility);
        }
        window.addEventListener('resize', checkArrowsVisibility);
        checkArrowsVisibility();
        return () => {
            if (carousel) {
                carousel.removeEventListener('scroll', checkArrowsVisibility);
            }
            window.removeEventListener('resize', checkArrowsVisibility);
        };
    }, [checkArrowsVisibility]);

    useEffect(() => {
        checkArrowsVisibility();
    }, [newsItems, checkArrowsVisibility]);

    useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [theme]);

    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    const isDark = document.body.classList.contains('dark');
                    setTheme(isDark ? 'dark' : 'light');
                }
            });
        });
    
        observer.observe(document.body, {
            attributes: true
        });
    
        return () => observer.disconnect();
    }, []);


    const handleClose = () => {
        if (iframeRef.current) {
        iframeRef.current.contentWindow?.postMessage(
            JSON.stringify({
            event: 'command',
            func: 'pauseVideo',
            }),
            '*',
        );
        }
    };


    return (
        <div id='newsSection' className='xl:mt-[100px] lg:mt-20 mt-16'>
            <div className='flex justify-between items-center'>
                {newsContent.imgUrl ? (
                    <img
                        src={theme === 'light' ? newsContent.imgUrlLight : newsContent.imgUrlDark}
                        alt={newsContent.altText}
                    /> 
                ) : (
                    <h2 className='text-custom-4 dark:text-white md:text-[32px] text-xl font-inter font-semibold md:leading-8 leading-[26px] m-0'>
                        { newsContent.title }
                    </h2>
                )}
                <a
                
                    href={ newsContent.url }
                    target='_blank'
                    rel='noreferrer'
                    className='text-custom-13 md:text-base text-sm font-inter font-semibold md:leading-[17.6px] leading-[15.4px] cursor-pointer'
                >
                    { newsContent.button }
                </a>
            </div>
            <div className="relative cursor-pointer"
                onMouseEnter={(e) => e.currentTarget.classList.add('hover')}
                onMouseLeave={(e) => e.currentTarget.classList.remove('hover')}
            >
                <div className='flex md:gap-8 gap-4 md:mt-6 mt-4 overflow-x-auto no-scrollbar' ref={carouselRef}>
                    <div>
                        <div className='flex flex-col bg-custom-13 flex-item rounded-xl w-[243px] h-full'>
                            <YouTubeVideoPlayer onClose={handleClose}/>
                            <a 
                                href={newsContent.podcastUrl}
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col justify-between h-full gap-4 py-6 px-4'
                            >
                                <div className='flex flex-col gap-2'>
                                    <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-normal m-0'>
                                        {newsContent.podcastTitle}
                                    </h3>
                                </div>
                                <div className='flex gap-2'>
                                    <img
                                        src={newsContent.playButtonImgUrl}
                                        alt="play button"
                                        width={35}
                                        height={35}
                                    />
                                    <h4 className='text-white text-sm font-inter font-normal leading-normal m-0'>
                                        {newsContent.podcastText}
                                    </h4>
                                </div>
                            </a>
                        </div>
                    </div>
                    { newsItems.map((news, index) => (
                        <a 
                            key={`news-${index}`}
                            href={ news.link }
                            target='_blank'
                            rel='noreferrer'
                        >
                            <div className='flex flex-col bg-custom-21 dark:bg-custom-22 flex-item rounded-xl w-[243px] h-full'>
                                <img
                                    className='w-full rounded-t-xl'
                                    src={ news['bnmedia:post-thumbnail']['bnmedia:url'] }
                                    alt="news thumbnail"
                                />
                                <div className='flex flex-col justify-between h-full gap-4 py-6 px-4'>
                                    <div className='flex flex-col gap-2'>
                                        <h3 className='text-custom-4 dark:text-white text-lg font-inter font-semibold leading-normal m-0'>
                                            { news.title }
                                        </h3>
                                        <p className='text-custom-23 dark:text-custom-20 text-sm font-inter font-normal leading-normal m-0'>
                                            { news['dc:creator'] }
                                        </p>
                                    </div>
                                    <p className='text-custom-13 text-base text-center font-inter font-semibold leading-[17.6px] border border-custom-13 border-solid rounded-xl py-2 px-4 m-0'>
                                        {newsContent.readButton}
                                    </p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                { !isTablet && showLeftArrow && (
                    <button className='absolute left-arrow -left-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(-1)}>
                        <SlArrowLeft color='#FFFFFF' />
                    </button>
                )}
                { !isTablet && showRightArrow && (
                    <button className='absolute right-arrow -right-6 top-1/2 transform -translate-y-1/2 cursor-pointer' onClick={() => scroll(1)}>
                        <SlArrowRight color='#FFFFFF' />
                    </button>
                )}
            </div>
        </div>
    )
}

export default NewsSection
