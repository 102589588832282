import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FooterList } from '../../helpers/data';
import SocialMedia from '../SocialMedia';

const Footer = () => {

    const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1024px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    let footerChunks = chunkArray(FooterList, isMobile ? 2 : 1);
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

    function chunkArray(array, size) {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    }

    useEffect(() => {
        localStorage.setItem("theme", theme);
    }, [theme]);

    useEffect(() => {
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === 'class') {
                    const isDark = document.body.classList.contains('dark');
                    setTheme(isDark ? 'dark' : 'light');
                }
            });
        });
    
        observer.observe(document.body, {
            attributes: true
        });
    
        return () => observer.disconnect();
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        const headerHeight = isMobile ? 132 : isTablet ? 174 : 155 ;
        if (element) {
          window.scrollTo({
            top: element.offsetTop - headerHeight,
            behavior: 'smooth',
          });
        }
    };

    return (
        <React.Fragment>
            <div className='flex md:justify-between md:gap-0 gap-8 xl:mt-[100px] lg:mt-20 mt-16'>
                { footerChunks.map((chunk, chunkIndex) => (
                    <div key={`chunk-${chunkIndex}`} className='flex flex-col'>
                        {chunk.map((section, sectionIndex) => (
                            <React.Fragment key={`section-${sectionIndex}`}>
                                <div className='mb-6 last:mb-0'>
                                    <h4 className='text-custom-1 dark:text-white text-lg font-bold leading-[26px] m-0 mb-2'>{ section.title }</h4>
                                    {section.links.map((link, index) => {
                                        return (
                                            link.idUrl ? (
                                                <p
                                                    key={`link-${index}`}
                                                    onClick={() => scrollToSection(link.url)}
                                                    className='block text-custom-2 hover:text-custom-1 dark:text-custom-6 dark:hover:text-white text-lg font-normal leading-8 cursor-pointer m-0'
                                                >
                                                    { link.name }
                                                </p>
                                            ) : (
                                                <a
                                                    key={`link-${index}`}
                                                    href={ link.url }
                                                    {...(link.externalLink ? { target: '_blank', rel: 'noreferrer' } : {})}
                                                    className='block text-custom-2 hover:text-custom-1 dark:text-custom-6 dark:hover:text-white text-lg font-normal leading-8 cursor-pointer'
                                                >
                                                    { link.name }
                                                </a>
                                            )
                                        )
                                    })}
                                </div>
                                { ((isTablet || isMobile) && footerChunks.length === chunkIndex + 1 && sectionIndex === 0) && (
                                    <SocialMedia theme={theme} />
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                ))}
                { (!isTablet && !isMobile) && (
                    <SocialMedia theme={theme} />
                )}
            </div>
            <div className='w-full mt-16'>
                <a href="/">
                    <img
                        src={theme === 'light' ? '/images/games-logo.svg' : '/images/games-logo-dark.svg'}
                        alt="Games logo"
                        width={260}
                        height={24}
                    />
                </a>
                <p className='text-custom-1 dark:text-white text-sm font-normal leading-[24.36px] m-0 mt-6'>
                    © 2024 Saint Bitts LLC
                    Bitcoin.com
                    All rights reserved.
                </p>
            </div>
        </React.Fragment>
    )
}

export default Footer
